<template>
  <fm-modal
    :mask-closable="false"
    :value="openDialog"
    width="800px"
    theme="mh-withe"
    @cancel="handleClose">
    <div>
      <fm-title title-text="查询配置" :title-menus="titleMenus" @clickTitleMenu="clickTitleMenu"></fm-title>
      <conf-table v-model="queryConf"></conf-table>
    </div>
  </fm-modal>
</template>

<script>
import {
  confQueryRequest
} from '@/api'

import ConfTable from './conf_table'
let dcopy = require('deep-copy')

let baseData = {
  table: '',
  alias: '',
  result: {
    isAll: true,
    fields: [],
    excludeColumns: []
  },
  joins: [],
  conditions: [],
  orders: []
}

export default {
  name: 'conf_modal',
  components: {
    ConfTable
  },
  props: {
    openDialog: {
      type: Boolean
    },
    confData: {
      type: Object
    }
  },
  data () {
    return {
      titleMenus: [{
        key: 'save',
        label: '保存'
      }],
      queryConf: baseData
    }
  },
  created () {
    let data = this.confData.conf ? JSON.parse(this.confData.conf) : {}
    this.queryConf = Object.assign({}, baseData, dcopy(data))
  },
  methods: {
    async clickTitleMenu () {
      await confQueryRequest.update(this.confData.id, Object.assign({}, this.confData, {
        conf: JSON.stringify(this.queryConf)
      }))
      this.$emit('dataUpdate')
      this.handleClose()
    },
    handleClose () {
      this.$emit('handleClose')
    }
  }
}
</script>

<style lang="less" scoped>
.title-c {
  display: flex;
  align-items: center;
}
.form-item-table {
  height: 10rem;
  width: 100%;
}
.form-item {
  display: flex;
  margin: 0.5rem 1rem;
  display: flex;
  align-items: center;
  .form-item-label {
    min-width: 5rem;
    max-width: 5rem;
    width: 5rem;
  }
  .form-item-content {
    flex: 1;
  }
}
</style>
