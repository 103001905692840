<template>
  <div class="sign-page">
    <div class="sign-page-div">
      <div class="sign-page-content content-block">
        <div class="content-top">
          <fm-title title-text="查询配置" :title-menus="titleMenus" @clickTitleMenu="clickTitleMenu">
          </fm-title>
        </div>
        <div class="content-body">
          <fm-table
            :column-list="columnList"
            :data-list="dataList"
            @tableAction="tableAction"
            :table-actions="tableActions"
            :show-search="true">
          </fm-table>
        </div>
      </div>
    </div>
    <data-update
      title-text="查询配置"
      :open-dialog.sync="openDialogEdit"
      :open-dialog-del.sync="openDialogDel"
      :request="request"
      :his-data="chooseData"
      @updateOver="loadData"
      @handleClose="openDialogEdit = false;openDialogDel = false"
      :form-parms="formParms">
    </data-update>
    <conf-modal
      v-if="openDialogConf"
      :open-dialog.sync="openDialogConf"
      @dataUpdate="loadData"
      @handleClose="openDialogConf = false"
      :conf-data="chooseData">
    </conf-modal>
  </div>
</template>

<script>
import ConfModal from './conf_query/conf_modal'
import {
  confQueryRequest as request
} from '@/api'

export default {
  components: {
    ConfModal
  },
  data () {
    return {
      request: request,
      chooseData: null,
      dataList: [],
      openDialogEdit: false,
      openDialogDel: false,
      openDialogConf: false,
      formParms: [{
        type: 'input',
        label: '标题',
        key: 'name'
      },
      {
        type: 'input',
        label: '编码',
        key: 'key'
      },
      {
        type: 'input',
        label: '配置',
        key: 'conf'
      }]
    }
  },
  computed: {
    titleMenus () {
      return [{
        key: 'add',
        label: '新增'
      }].filter(({key}) => this.$authFunsProxy[key])
    },
    tableActions () {
      return [{
        key: 'conf',
        label: '配置'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }].filter(({key}) => this.$authFunsProxy[key])
    },
    columnList () {
      return [{
        field: 'name',
        title: '名称'
      },
      {
        field: 'key',
        title: '编码'
      },
      {
        field: 'conf',
        title: '配置'
      }]
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    async loadData () {
      if (this.$authFunsProxy.get) {
        this.chooseData = null
        this.dataList = await request.get()
      }
    },
    formHandleClose () {
      this.openDialog = false
    },
    async clickTitleMenu (parm) {
      this.chooseData = null
      if (parm === 'add') {
        this.openDialogEdit = true
      }
    },
    async tableAction (parm) {
      this.chooseData = parm.data
      if (parm.action === 'edit') {
        this.openDialogEdit = true
      } else if (parm.action === 'del') {
        this.openDialogDel = true
      } else if (parm.action === 'conf') {
        this.openDialogConf = true
      }
    }
  }
}
</script>

<style scoped lang="less">
@import '../../styles/values.less';
.content-bottom {
  height: 4.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-page-div {
  margin: 1rem 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.sign-page-div-no-padding {
  margin: 0;
}
.sign-page {
  width: 100%;
  height: 100%;
  display: flex;
}
.sign-page-top-1 {
  margin-bottom: 1rem;
}
.sign-page-top-2 {
  margin-bottom: 1.25rem;
}
.sign-page-content {
  flex: 1;
}
.content-body {
  flex: 1;
}
</style>
