<template>
  <div class="f-p">
    <div class="f-p-data">
      <fm-table
        :column-list="columnList"
        :data-list="[{}].concat(dataList)"
        @tableAction="tableAction"
        :table-actions="tableActions">
      </fm-table>
    </div>
    <fm-form-dialog
      :mask-closable="false"
      form-title="排序"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="chooseData"
      form-width="800px"
      label-alone
      label-align="left"
      @formSubmit="formSubmit"
      @handleClose="handleClose">
    </fm-form-dialog>
  </div>
</template>

<script>
let dcopy = require('deep-copy')

export default {
  name: 'column',
  props: {
    value: Array
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  watch: {
    value: {
      handler () {
        this.dataList = dcopy(this.value)
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    columnList () {
      return [{
        title: '字段',
        field: 'field'
      },
      {
        title: '方向',
        field: 'direction',
        render: function (h, rowData) {
          return h('div', ([{key: 'asc', label: '正序'}, {key: 'desc', label: '倒序'}].find(v => v.key === rowData.direction) || {}).label || '')
        }
      }]
    },
    formParms () {
      return [{
        type: 'input',
        label: '字段',
        key: 'field',
        check: {
          required: true
        }
      },
      {
        type: 'select',
        label: '方向',
        key: 'direction',
        selectDatas: [{key: 'asc', label: '正序'}, {key: 'desc', label: '倒序'}],
        check: {
          required: true
        }
      }]
    }
  },
  data () {
    return {
      isNew: false,
      chooseData: null,
      tableActions: [{
        key: 'add',
        label: '新增',
        show: (data) => {
          return !data.field
        }
      },
      {
        key: 'del',
        label: '删除',
        show: (data) => {
          return data.field
        }
      }],
      dataList: [],
      openDialog: false
    }
  },
  methods: {
    tableAction (parm) {
      if (parm.action === 'add') {
        this.isNew = true
        this.chooseData = null
        this.openDialog = true
      } else if (parm.action === 'del') {
        this.chooseData = parm.data
        this.dataList = this.dataList.filter(v => v.field !== this.chooseData.field)
        this.$emit('change', this.dataList.map((v) => {
          let a = {}
          Object.keys(v).forEach((v2) => {
            a[v2] = v[v2]
          })
          return a
        }))
      }
    },
    handleClose () {
      this.openDialog = false
    },
    formSubmit (data, resolve) {
      if (this.isNew) {
        this.dataList.push(data)
      } else {
        let i = 0
        while (i < this.dataList.length) {
          if (this.dataList[i].key === data.key) {
            this.dataList[i] = data
          }
          i += 1
        }
      }
      this.$emit('change', this.dataList.map((v) => {
        let a = {}
        Object.keys(v).forEach((v2) => {
          a[v2] = v[v2]
        })
        return a
      }))
      resolve()
      this.openDialog = false
    },
    conf () {
      this.openDialog = true
    }
  }
}
</script>

<style lang="less" scoped>
.f-p {
  align-items: center;
  margin-left: 1rem;
  width: calc(100% - 1rem);
  .f-p-data {
    border: 1px solid #dcdee2;
    border-top: 0px;
    height: 10rem;
    width: calc(100% - 1rem);
  }
}
</style>
